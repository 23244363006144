@font-face {
    font-family: 'Eurostile Extended';
    src: url('../../fonts/EurostileExtendedBlack.ttf') format('truetype');
}
@font-face {
    font-family: 'InterFace';
    src: url('https://use.typekit.net/cgg4bmy.css');
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Eurostile Extended', sans-serif;
}

body, p {
    font-family: 'InterFace', sans-serif;
}