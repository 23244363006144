@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/fonts.css';

body {
  margin: 0;
  background: #f1f1f1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @apply text-3xl font-semibold text-gray-800;
}

p {
  @apply text-base text-gray-700;
}

label {
  @apply block text-base font-semibold leading-6 text-gray-800;
}

.btn-primary {
  @apply px-4 py-2 bg-blue-800 text-center text-white text-sm font-semibold rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50;
}

.btn-disabled {
  @apply px-4 py-2 bg-gray-500 text-center text-white text-sm font-semibold rounded-md;
}

.input-text {
  @apply border text-sm border-gray-300 rounded-md px-4 py-1 focus:outline-none focus:ring-blue-800 focus:border-blue-800
}

.link {
  @apply font-semibold text-blue-800 hover:text-indigo-500
}

.formik-input {
  @apply w-full mb-2 border text-sm border-gray-300 rounded-md px-4 py-1 focus:outline-none focus:ring-blue-800 focus:border-blue-800;
}

.formik-checkbox {
  @apply h-4 w-4 mr-2 rounded-md
}