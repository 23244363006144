.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  padding: 3px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 16px;
  /* Add any additional styles as needed */
}